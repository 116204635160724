/* Futura font */
@import url("https://use.typekit.net/djq6mbs.css");

@font-face {
  font-family: "ServiceStation";
  src: url("assets/fonts/service-station-display/ServiceStation-Regular.woff")
    format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "ServiceStation";
  src: url("assets/fonts/service-station-display/ServiceStation-RegularItalic.woff")
    format("woff");
  font-style: italic;
}

@font-face {
  font-family: "ServiceStation";
  src: url("assets/fonts/service-station-display/ServiceStation-Rough.woff")
    format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "ServiceStation";
  src: url("assets/fonts/service-station-display/ServiceStation-RoughItalic.woff")
    format("woff");
  font-style: italic;
  font-weight: bold;
}

:root {
  box-sizing: border-box;

  --bg-color: #455483;
  --bg-color-dark: #252d44;
  --white: white;
  --gold: #fbdaaa;
  --brown: #704633;

  --base-unit: 12px;
  --base-unit-1: calc(1 * var(--base-unit));
  --base-unit-2: calc(2 * var(--base-unit));
  --base-unit-4: calc(4 * var(--base-unit));
  --base-unit-8: calc(8 * var(--base-unit));
  --base-unit-16: calc(16 * var(--base-unit));

  --font-primary: Futura, "futura-pt", "Trebuchet MS", sans-serif;
  --font-secondary: ServiceStation, "Trebuchet MS", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0px;
  margin: 0px;
  font-family: var(--font-primary);
  font-size: calc(1.5 * var(--base-unit));
  color: var(--white);
  background: var(--bg-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: var(--font-secondary);
}

h1 {
  font-size: var(--base-unit-8);
}

h2 {
  font-size: var(--base-unit-4);
}

h3 {
  font-size: var(--base-unit-2);
}

h4 {
  font-size: calc(1.5 * var(--base-unit-1));
}

h5,
h6 {
  font-size: var(--base-unit-1);
}

a {
  color: var(--white);
  font-weight: bold;
  transition: 200ms ease-in-out;
}

a:visited {
  opacity: 0.8;
  color: var(--white);
}

a:hover {
  color: var(--gold);
}

.full {
  min-height: 100vh;
}

#est-nav__ham svg {
  position: fixed;
  z-index: 10001;
}

#est-nav__ham input {
  position: fixed;
  z-index: 10001;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#est-nav__ham input:checked ~ .hamRotate {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #fff;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
#est-nav__ham input:checked ~ .ham1 .top {
  stroke-dashoffset: -98px;
}
#est-nav__ham input:checked ~ .ham1 .bottom {
  stroke-dashoffset: -138px;
}

#est-nav__ham input {
  display: block;
  width: 80px;
  height: 80px;
  position: fixed;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 10005; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

.drawer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  z-index: 10000;

  background: rgba(244, 232, 192, 0.7);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.mobile-links {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: var(--font-secondary);
  font-size: 25px;
  left: 5px;
  top: 10%;
}

#mlh-logo-container {
  position: absolute;
  top: 0px;
  left: 30px;
  animation: 1.5s cubic-bezier(0.22, 1, 0.36, 1) slidein--top;
  z-index: 2001;
}

#mlh-logo-container img {
  height: 130px;
  width: 80px;
  padding: 0px;
}

#est-nav__ham input:checked ~ .drawer {
  transform: none;
}

#desktop-nav {
  position: fixed;
  background: transparent;
  font-family: var(--font-secondary);
  width: 100%;
  z-index: 2500;
  opacity: 0.7;
  transition: opacity 0.2s ease-out;
}

#desktop-nav:hover {
  opacity: 1;
}

nav ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
}

nav li {
  margin: var(--base-unit-2) var(--base-unit-2);
}

nav a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.15s ease-in;
}

nav a:visited {
  color: white;
}

nav a:hover {
  color: var(--gold);
}

#home--inner {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  z-index: 2000;
  background: #423a36;
}

#society {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1440px;
  height: 900px;
  background: #293c6f;
  box-shadow: 0px 0px 50px #fbdaaa;
}

#landing-page-layers {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#landing-page-layers > img {
  position: absolute;
  z-index: 900;
}

#wood-panel {
  height: 700px;
}

#front-gate {
  height: 100%;
}

#people {
  bottom: 0px;
}

#closest-stalls {
}

#closer-stalls {
  height: 100%;
  top: -20px;
}

#further-stalls {
  top: 450px;
}

#furthest-stalls {
  top: 510px;
  left: 45.7%;
}

.abs-vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.abs-horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

.abs-top {
  top: 0px;
}

.abs-bottom {
  bottom: 0px;
}

#splash {
  position: relative;
  top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--gold);
  font-family: var(--font-secondary);
  z-index: 1000;
}

#splash h1,
p {
  margin: var(--base-unit-1);
}

#splash p {
  font-size: var(--base-unit-4);
}

.home-scrolljack-container {
  z-index: 2000;
}

.home-scrolljack-container--inner {
  position: sticky;
  top: 0px;
  z-index: 2000;
}

#logo-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
}

#logo-group img {
  margin-left: 20px;
  margin-right: 20px;
}

#logo-group a:hover {
  transition: all .25s ease-in-out;
}

#logo-group a:hover {
  transform: scale(1.06);
}

#tracks {
  z-index: 2;
}

#tracks img {
  margin-top: var(--base-unit-4);
  margin-bottom: var(--base-unit-2);
  margin-left: var(--base-unit-1);
  margin-right: var(--base-unit-1);
  height: 300px;
  cursor: pointer;
}

#tracks h2 {
  margin-bottom: var(--base-unit-1);
}

#tracks-booths {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, minmax(400px, auto));
  justify-items: center;
}

#general-track-booth {
  grid-column: 1/2;
  grid-row: 1/2;
}

#emerging-track-booth {
  grid-column: 2/3;
  grid-row: 1/2;
}

.track-showcase {
  grid-column: 1/3;
  grid-row: 2/3;
}

.track h3,
h4 {
  text-align: center;
  font-family: var(--font-primary);
}

.btn {
  font-family: var(--font-primary);
  text-transform: uppercase;
  background: var(--gold);
  color: var(--brown);
  padding: var(--base-unit-1);
  border-radius: calc(0.5 * var(--base-unit));
  transition: all 0.15s ease-in;
}

.btn:hover {
  background: var(--brown);
  color: var(--gold);
}

a.btn {
  color: var(--brown);
  text-decoration: none;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
}

section.center {
  margin: var(--base-unit-4) 25%;
}

#about-more {
  position: relative;
}

/* IMAGES */

.top-left {
  position: absolute;
  top: 16%;
  left: -25%;
  width: 35%;
}

.top-left img {
  width: 90%;
}

.top-right {
  position: absolute;
  top: 12%;
  right: -30%;
  width: 35%;
}

.top-right img {
  width: 90%;
}

.bottom-left {
  position: absolute;
  top: 64%;
  left: -20%;
  width: 35%;
}

.bottom-left img {
  width: 90%;
}

.bottom-right {
  position: absolute;
  top: 62%;
  right: -30%;
  width: 35%;
}

.bottom-right img {
  width: 90%;
}

#icecream-bottom {
  top: -15%;
  left: -25%;
}

#sushi {
  top: 10%;
  right: -40%;
}

#sushi:hover {
  cursor: zoom-in;
}

#fish {
  top: 65%;
  right: -40%;
}

#mask-top {
  top: 15%;
  left: -20%;
}

#mask-top img {
  width: 70%;
}

#mask-bottom {
  top: 75%;
  right: -40%;
  width: 40%;
}

#mask-bottom img {
  width: 70%;
}

.light-span {
  width: 100%;
  z-index: -1;
  position: absolute;
}

#first-lspan {
  transform: translateY(-80%)
}

#second-lspan {
  transform: translateY(-30%)
}

#third-lspan {
  transform: translateY(50%)
}

.lamp {
  position: relative;
  z-index: 0;
  position: absolute;
  top: -5%;
  left: -22%;
}

.lamp-beneath {
  position: relative;
  z-index: 1;
  padding-bottom: 200px;
  /* transform: translate(0, -400px); */
}

#popsicle {
  top: -10%;
  right: -30%;
}

#laptop {
  top: 60%;
  right: -25%;
}

.faq {
  width: 100%;
  margin-bottom: var(--base-unit-2);
}

.faq .question {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: var(--font-primary);
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
  background: #fff8e14d;
  padding: var(--base-unit-1) var(--base-unit-2);
  border-radius: var(--base-unit-2);
  cursor: pointer;
  border: none;
  width: 100%;
  z-index: 1000;
  text-align: left;
}

.faq .answer {
  position: relative;
  background: rgba(255, 248, 225, 0.1);
  border-radius: var(--base-unit-2);
  padding: var(--base-unit-2);
  padding-top: var(--base-unit-4);
  transform: translateY(calc(-1 * var(--base-unit-2)));
  z-index: 900;
  text-align: left;
}

#faqs {
  position: relative;
}

.hide {
  display: none;
}

#about {
  position: relative;
  color: #2c3d6b;
  background-size: cover;
  background: url("./assets/about.jpg") no-repeat center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#about .blob {
  overflow: hidden;
}

#about .blob .content {
  position: absolute;
  top: 50%;
  transform: translateY(calc(60px + -50%));
}

#about .blob .content::before {
  content: "";
  float: left;
  height: 500px;
  width: 500px;
  shape-outside: polygon(
    93px 200px,
    289px 1px,
    24px 4px,
    23px 498px,
    123px 496px,
    34px 367px,
    52px 256px
  );
  shape-margin: 3%;
}

#about .blob .content p {
  width: 1000px;
}

#about .blob .content p::before {
  content: "";
  float: right;
  height: 500px;
  width: 500px;
  shape-outside: polygon(
    288px 7px,
    311px 5px,
    311px 371px,
    73px 371px,
    220px 272px,
    270px 192px,
    300px 84px
  );
  shape-margin: 3%;
}

#about .blob .content::after {
}

#covid {
  position: relative;
}

.show {
  display: block;
}

#code-of-conduct {
  padding-top: 15px;
}

#code-of-conduct a {
  font-size: calc(1.1 * var(--base-unit));
  text-decoration: none;
  color: var(--gold);
}

#code-of-conduct a:hover {
  transform: scale(1.1);
  color: var(--white);
}

footer {
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: var(--base-unit-4);
}

footer .socials ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
}

footer .socials li {
  margin: calc(0.25 * var(--base-unit-1));
  transition: transform 0.1s ease-out;
}

footer .socials li:hover {
  transform: scale(1.1);
}

footer .socials li img {
  height: calc(1.25 * var(--base-unit-2));
  width: auto;
}

.logo {
  font-size: var(--base-unit-4);
  display: flex;
  align-items: center;
}

.logo img {
  margin-right: var(--base-unit-1);
}

.upper-border {
  border-top: 4px solid black;
}

.justify-flex-end {
  justify-content: flex-end;
}

@media only screen and (max-width: 1335px) {
  .top-left {
    top: 20%;
    /* left: -25%; */
  }
  

  .top-right {
    top: 20%;
    /* right: -30%; */
  }
  

  .bottom-left {
    top: 70%;
    left: -25%;
  }
  

  .bottom-right {
    top: 70%;
    right: -30%;
  }

  #mask-bottom {
    right: -40%;
  }

  .lamp {
    top: -5%;
    left: -30%;
  }

  #icecream-bottom {
    top: -10%;
  }

  #popsicle {
    width: 40%;
  }

  #laptop {
    width: 40%;
    right: -33%;
  }
   
}

@media (max-width: 1200px) {
}

@media (max-width: 1200px) {
  .top-left {
    width: 40%;
  }
  .top-right {
    width: 40%;
  }
  .bottom-left img {
    width: 90%;
  }
  .bottom-right img {
    width: 90%;
  }

  #sushi {
    /* top: -5%;
    right: -50%; */
  }
  
  #sushi img {
    /* width: 70%; */
  }

  .lamp {
    top: -5%;
    left: -36%;
  }

  #mask-top {
    left: -30%;
  }

  #mask-bottom {
    right: -44%;
  }
  #popsicle {
    right: -40%;
  }
  
}

@media (max-width: 850px) {
  #logo-group {
    flex-direction: column;
  }

  #logo-group img {
    margin: 1em 0;
  }

  svg#blob {
    position: relative;
    transform: translateX(-20%);
  }

  #about .blob .content::before {
    display: none;
  }

  #about .blob .content p::before {
    display: none;
  }

  #about .blob .content {
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
  }

  #about .blob .content p {
    width: 60vw;
  }

  .top-left {
    top: 7%;
    width: 55%;
    left: -43%;
  }

  .top-left img {
    width: 100%;
  }
  

  .top-right {
    top: 7%;
    width: 60%;
    right: -45%;
  }
  

  .bottom-left {
    top: 75%;
    width: 47%;
    left: -40%;
  }
  

  .bottom-right {
    top: 75%;
    width: 45%;
    right: -36%;
  }

  .bottom-right img {
    width: 100%;
  }
  

  .light-span {
    display: none;
  }

  /* #lightbulb {
    top: 7%;
    width: 55%;
    left: -43%;
  }

  #lightbulb img {
    width: 100%;
  }

  #icecream-top {
    top: 7%;
    width: 60%;
    right: -45%;
  }
  
  #icecream-top img {
    width: 100%;
  }
  
  #skewers {
    top: 75%;
    width: 47%;
    left: -40%;
  }

  #apples {
    top: 75%;
    width: 45%;
    right: -36%;
  }
  
  #apples img {
    width: 100%;
  } */
  

  #icecream-bottom {
    top: -8%;
    left: -50%;
  }

  #icecream-bottom img {
    width: 90%;
  }
  

  #sushi {
    top: -2%;
    right: -40%;
    width: 46%;
  }

  #sushi img {
    width: 100%;
  }

  #fish {
    right: -45%;
    width: 45%;
  }

  #fish img {
    width: 100%;
  }

  #mask-top {
    top: 4%;
    left: -37%;
  }

  #mask-top img {
    width: 60%;
  }

  #mask-bottom {
    width: 44%;
    right: -44%;
  }
  
  #mask-bottom img {
    width: 100%;
  }
  
  #popsicle {
    width: 60%;
    left: -60%;
  }

  #popsicle img {
    width: 100%;
  }

  #laptop {
    right: -46%;
    width: 50%;
  }

  .lamp {
    display: none;
  }

  .lamp-beneath {
    z-index: 0;
    transform: translateY(2%);
  }
}

@media (max-width: 700px) {
  .track-prompt {
    display: none;
  }

  #tracks-booths {
    display: flex;
    flex-direction: column;
  }

  .track {
    display: block !important;
  }

  #skewers,
  #lightbulb {
    /* left: -40%; */
  }

  #icecream-bottom,
  #sushi {
    top: 0%;
  }

  #fish {
    top: 70%;
  }

  #mask-top {
    top: 4%;
    left: -40%;
    width: 50%;
  }

  #popsicle {
    top: 0%;
    left: -60%;
  }
}

@media (min-width: 601px) {
  #est-nav__ham {
    display: none;
  }
}

@media (max-width: 600px) {
  #mlh-logo-container {
    display: none;
  }

  .est-nav__display--mobile {
    transform: translateX(-100%);
  }

  :root {
    --base-unit: 10px;
  }

  .light-span {
    display: none;
  }

  #lightbulb,
  #apples,
  #skewers,
  #icecream-top,
  #popsicle,
  #laptop {
    display: none;
  }

  #mask-top {
    top: 9%;
    left: 35%;
  }

  #mask-bottom {
    top: 80%;
    right: 29%;
  }

  #icecream-bottom {
    top: -12%;
    left: -12%;
    width: 50%;
  }

  #sushi {
    top: -3%;
    right: -6%;
    width: 36%;
  }
  

  #fish {
    display: none;
  }

  nav {
    display: none;
  }

  #est-nav__ham {
    display: block;
  }

  section.center {
    margin: var(--base-unit-4) 40px;
  }

  #tracks img {
    height: 200px;
  }

  svg#blob {
    position: relative;
    transform: translateX(-30%);
  }

  #about .blob .content {
    left: 50%;
  }

  #about .blob .content p {
    width: 80vw;
  }
}

@media (max-width: 350px) {
  :root {
    --base-unit: 8px;
  }

  .track {
    margin: var(--base-unit-2);
  }

  svg#blob {
    position: relative;
    transform: translateX(-30%);
  }
}

@media (max-height: 700px) {
  #splash {
    top: 150px;
  }
}
